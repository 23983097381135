<template>
  <div class="assignmentSchedule">
    <div style="margin-bottom: 30px">
      <!-- sesudah lempar props preview assignment {{session.tipe_assignment}} -->
      <!-- {{taskType}} -->
      <a-divider orientation="left">
        <h3>Assignment Info</h3>
      </a-divider>
      <div class="paddingLeft">
        <a-row>
          <a-col
            :span="isMobile ? 24 : 10"
            :class="[isMobile ? 'text-center' : '']"
          >
            <label style="margin-bottom: 2% !important">
              <b>Type</b>
            </label>
            <template v-if="taskObj">
              <p :style="{ color: taskObj.type == null ? 'gray' : '#1B55E3' }">
                <a-icon :type="taskObj.icon" />
                {{ taskObj.type == null ? "No Assignment" : taskObj.type }}
              </p>
            </template>
          </a-col>
          <a-col
            :span="isMobile ? 24 : 14 "
            :class="[isMobile ? 'text-center' : '', isMobile ? 'mt-3' : '']"
          >
            <template
              v-if="!assignmentStudent || !assignmentStudent.tugas_nilai"
            >
              <label style="margin-bottom: 2% !important">
                <b>Due Date</b>
              </label>
              <p>
                {{
                  this.session.tugas_deadline2 == null
                    ? "No Due Date"
                    : secondDueDateAndTime
                }}
              </p>
            </template>
            <a-row v-else>
              <a-col :span="5">
                <label style="margin-bottom: 2% !important">
                  <b>Result</b>
                </label>
                <h1
                  :style="{
                    color: color(assignmentStudent.tugas_nilai),
                  }"
                >
                  {{ assignmentStudent.tugas_nilai }}
                </h1>
              </a-col>
              <a-col :span="19">
                <label style="margin-bottom: 2% !important">
                  <b>Feedback</b>
                </label>
                <p>{{ assignmentStudent.feedback }}</p>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </div>
    <div style="margin-bottom: 30px">
      <a-divider orientation="left">
        <h3>Details</h3>
      </a-divider>
      <div :class="[taskType ? 'textAssignment' : '']">
        <div v-if="taskType === 'File Upload'">
          <span ref="deskripsi" v-html="session.tugas_deskripsi"></span>
          <a-form-item style="margin-top: 5%" v-if="!isTeacher">
            <h4>Your Answer</h4>
            <a-row v-if="statusFileDone" :gutter="[16, 16]">
              <a-col :xs="24" :lg="20">
                <a-input
                  :value="fileAnswerDone.tugas_filename"
                  read-only
                  style="height: 48px"
                />
                <small class="text-secondary">File with format (docx,xlsx, pdf, jpg/jpeg, png) are allowed to upload.</small>
              </a-col>
              <a-col :xs="24" :lg="2">
                <a-button
                  @click="
                    downloadFile(
                      fileAnswerDone.tugas_filepath,
                      fileAnswerDone.tugas_filename
                    )
                  "
                  icon="download"
                  style="width: 100%; height: 48px"
                ></a-button>
              </a-col>
              <a-col :xs="24" :lg="2">
                <a-button
                  @click.prevent="
                    deleteFile(
                      fileAnswerDone.id,
                      fileAnswerDone.tugas_filepath,
                      fileAnswerDone.tugas_filename
                    )
                  "
                  type="danger"
                  icon="close"
                  style="width: 100%; height: 48px"
                  :loading="loadingDelete"
                  :disabled="assignmentStudent.tugas_nilai != null"
                ></a-button>
              </a-col>
            </a-row>
            <form
              v-else
              @submit.prevent="addFile"
              enctype="multipart/form-data"
            >
              <a-row :gutter="[16, 16]">
                <a-col :xs="24" :lg="20">
                  <a-input
                    class="pt-4 pb-5"
                    type="file"
                    ref="file"
                    placeholder="Upload additional documents or files for this session"
                    style="height: 48px"
                    @change="selectFile"
                  />
                  <small class="text-secondary">File with format (docx,xlsx, pdf, jpg/jpeg, png) are allowed to upload.</small>
                </a-col>
                <a-col :xs="24" :lg="3">
                  <a-button
                    @click.prevent="addFile()"
                    :type="!fileAnswer ? 'secondary' : 'primary'"
                    icon="upload"
                    style="width: 100%; height: 70px"
                    :loading="loadingAdd"
                    :disabled="!fileAnswer"
                  ></a-button>
                </a-col>
              </a-row>
            </form>
          </a-form-item>
          <div v-else></div>
        </div>
        <div v-else-if="taskType === 'Multiple Choice'" class="textAssignment">
          <h3 v-if="!isTeacher">Your Answer</h3>
          <!-- {{multipleChoiceAnswer}} -->
          <span ref="deskripsi" v-html="session.tugas_deskripsi"></span>
          <div
            v-for="(item, index) in session.pg_soals"
            :key="index"
            class="mt-5"
          >
            <a-row>
              <a-col :span="2">
                <h3>{{ numberQuestion(index) }}.</h3>
              </a-col>
              <a-col :span="22">
                <p class="mb-3" ref="soal" v-html="item.soal"></p>
                <template v-for="number in institusi.jumlah_pilihan_ganda || 4">
                  <div class="d-flex flex-column mb-3" :key="number" v-if="item[`pilihan_${String.fromCharCode(65 + (number - 1)).toLowerCase()}`]">
                    <div class="d-flex flex-row align-items-start">
                      <div>
                        <a-button
                          v-if="isTeacher"
                          :type="item.jawaban === String.fromCharCode(65 + (number - 1)) ? 'primary' : 'default'"
                          shape="round"
                          class="d-inline-block"
                          >{{String.fromCharCode(65 + (number - 1))}}</a-button
                        >
                        <a-button
                          v-if="doAssignment"
                          :type="
                            multipleChoiceAnswer[index] === String.fromCharCode(65 + (number - 1))
                              ? 'primary'
                              : 'default'
                          "
                          shape="round"
                          class="d-inline-block"
                          @click.prevent="answerQuestion(index, String.fromCharCode(65 + (number - 1)))"
                          >{{String.fromCharCode(65 + (number - 1))}}</a-button
                        >
                        <span
                          v-else-if="!isTeacher"
                          :class="doAssignment ? 'd-none' : 'd-inline-block'"
                          >{{String.fromCharCode(65 + (number - 1))}}.</span
                        >
                      </div>
                      <div class="ml-3">
                        <span ref="jawaban" v-html="item[`pilihan_${String.fromCharCode(65 + (number - 1)).toLowerCase()}`]"></span>
                        <span
                          v-if="
                            !isTeacher &&
                            !doAssignment &&
                            multipleChoiceAnswer[index] === String.fromCharCode(65 + (number - 1))
                          "
                          class="text-success"
                          >&nbsp;(Your Answer)</span
                        >
                      </div>
                    </div>
                  </div>
                </template>
                <!-- <div class="d-flex flex-column mb-3">
                  <div class="d-flex flex-row align-items-start">
                    <div>
                      <a-button
                        v-if="isTeacher"
                        :type="item.jawaban === 'B' ? 'primary' : 'default'"
                        shape="round"
                        class="d-inline-block"
                        >B</a-button
                      >
                      <a-button
                        v-if="doAssignment"
                        :type="
                          multipleChoiceAnswer[index] === 'B'
                            ? 'primary'
                            : 'default'
                        "
                        shape="round"
                        class="d-inline-block"
                        @click.prevent="answerQuestion(index, 'B')"
                        >B</a-button
                      >
                      <span
                        v-else-if="!isTeacher"
                        :class="doAssignment ? 'd-none' : 'd-inline-block'"
                        >B.</span
                      >
                    </div>
                    <div class="ml-3">
                      <span v-html="item.pilihan_b"></span>
                      <span
                        v-if="
                          !isTeacher &&
                          !doAssignment &&
                          multipleChoiceAnswer[index] === 'B'
                        "
                        class="text-success"
                        >&nbsp;(Your Answer)</span
                      >
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column mb-3">
                  <div class="d-flex flex-row align-items-start">
                    <div>
                      <a-button
                        v-if="isTeacher"
                        :type="item.jawaban === 'C' ? 'primary' : 'default'"
                        shape="round"
                        class="d-inline-block"
                        >C</a-button
                      >
                      <a-button
                        v-if="doAssignment"
                        :type="
                          multipleChoiceAnswer[index] === 'C'
                            ? 'primary'
                            : 'default'
                        "
                        shape="round"
                        class="d-inline-block"
                        @click.prevent="answerQuestion(index, 'C')"
                        >C</a-button
                      >
                      <span
                        v-else-if="!isTeacher"
                        :class="doAssignment ? 'd-none' : 'd-inline-block'"
                        >C.</span
                      >
                    </div>
                    <div class="ml-3">
                      <span v-html="item.pilihan_c"></span>
                      <span
                        v-if="
                          !isTeacher &&
                          !doAssignment &&
                          multipleChoiceAnswer[index] === 'C'
                        "
                        class="text-success"
                        >&nbsp;(Your Answer)</span
                      >
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column mb-3">
                  <div class="d-flex flex-row align-items-start">
                    <div>
                      <a-button
                        v-if="isTeacher"
                        :type="item.jawaban === 'D' ? 'primary' : 'default'"
                        shape="round"
                        class="d-inline-block"
                        >D</a-button
                      >
                      <a-button
                        v-if="doAssignment"
                        :type="
                          multipleChoiceAnswer[index] === 'D'
                            ? 'primary'
                            : 'default'
                        "
                        shape="round"
                        class="d-inline-block"
                        @click.prevent="answerQuestion(index, 'D')"
                        >D</a-button
                      >
                      <span
                        v-else-if="!isTeacher"
                        :class="doAssignment ? 'd-none' : 'd-inline-block'"
                        >D.</span
                      >
                    </div>
                    <div class="ml-3">
                      <span v-html="item.pilihan_d"></span>
                      <span
                        v-if="
                          !isTeacher &&
                          !doAssignment &&
                          multipleChoiceAnswer[index] === 'D'
                        "
                        class="text-success"
                        >&nbsp;(Your Answer)</span
                      >
                    </div>
                  </div>
                </div> -->
              </a-col>
            </a-row>
          </div>
        </div>
        <div v-else-if="taskType === 'Essay'">
          <span ref="deskripsi" v-html="session.tugas_deskripsi"></span>
          <div v-if="!isTeacher">
            <h4>Your Answer</h4>
            <br />
            <div v-if="doAssignment" class="ckEditorContainer">
              <ckeditor
                :editor="editor"
                v-model="essayAnswer"
                @input="changedEssayAnswer"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <!-- <wysiwyg
              v-if="doAssignment"
              style="height: 20em"
              @change="changedEssayAnswer"
              v-model="essayAnswer"
            />-->
            <div ref="jawaban" v-else v-html="essayAnswer"></div>
          </div>
        </div>
        <div v-else>
          <EmptyState
            :mobile="isMobile"
            heading="No Assignment"
            description="There is no assignment on this session."
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import EmptyState from '@/components/app/EmptyState'
import config from '@/config'
import { renderMathMl } from '@/helpers/mathjax'
export default {
  props: ['doAssignment', 'session', 'taskType', 'attendanceIsNull', 'isTeacher', 'assignmentStudent'],
  data() {
    return {
      loadingAdd: false,
      loadingDelete: false,
      fileAnswer: '',
      statusFileDone: false,
      fileAnswerDone: {},
      essayAnswer: '',
      multipleChoiceAnswer: [],
      errorFile: {
        status: false,
        message: '',
      },
      taskTypes: [
        {
          type: 'Multiple Choice',
          icon: 'unordered-list',
        }, {
          type: 'Essay',
          icon: 'align-left',
        }, {
          type: 'File Upload',
          icon: 'paper-clip',
        }, {
          type: null,
          icon: 'minus-circle',
        },
      ],
    }
  },
  methods: {
    color(nilai) {
      if (nilai < 25) {
        return '#FF0000'
      } else if (nilai >= 25 && nilai < 50) {
        return '#FF8811'
      } else if (nilai >= 50 && nilai < 75) {
        return '#F9E900'
      } else if (nilai >= 75 && nilai < 100) {
        return '#7ABE28'
      } else {
        return '#41B883'
      }
    },
    changedEssayAnswer(payload) {
      this.$emit('changedEssayAnswer', payload)
    },
    numberQuestion(index) {
      return ++index
    },
    answerQuestion(index, option) {
      this.multipleChoiceAnswer.splice(index, 1, option)
      this.$emit('changedMultipleChoiceAnswer', this.multipleChoiceAnswer)
      // console.log(this.multipleChoiceAnswer)
    },
    downloadFile(path, filename) {
      const href = `${config.apiUrl}/${path}${filename}`
      window.open(href, '_blank')
    },
    async deleteFile(id, filepath, filename) {
      try {
        this.loadingDelete = true
        await this.$store.dispatch('slot/DELETE_FILE_ASSIGNMENT_MURID', {
          id: id,
          id_sesi: this.currentSlot.id,
          id_murid: this.user.id,
        })
        this.$notification.success({
          message: 'SUCCESS',
          description: 'Successfully delete file.',
        })
        this.statusFileDone = false
      } catch (err) {
        this.$notification.error({
          message: 'FAILED',
          description: 'failed on deleting file.',
        })
        console.log(err)
      }
      this.loadingDelete = false
    },
    async addFile() {
      this.loadingAdd = true
      const formData = new FormData()
      const idMurid = this.user.id
      const idSesi = this.currentSlot.id
      const filepath = 'Assignments/' + idSesi + '/'
      formData.append('id_murid', idMurid)
      formData.append('id_sesi', idSesi)
      formData.append('filepath', filepath)
      formData.append('fileUploadAssignment', this.fileAnswer)
      // console.log(...formData)
      try {
        // VUEX GABISA LEMPAR FILE.. MANGKANYA ADA AXIOS DISINI.. TRUST ME IT WORKS
        await axios.put(`${config.apiUrl}/api/attendance/update-file-answer`, formData, {
          headers: {
            token: localStorage.token,
          },
        })
        setTimeout(async () => {
          const data = await this.$store.dispatch('slot/FETCH_USER_ASSIGNMENT_BY_ID_SESSION', { idMurid, idSesi })
          if (data.data) {
            if (data.data.tugas_filename) {
              this.statusFileDone = true
              this.fileAnswerDone.id = data.data.id
              this.fileAnswerDone.tugas_filepath = data.data.tugas_filepath
              this.fileAnswerDone.tugas_filename = data.data.tugas_filename
            }
          } else {
            this.$emit('changeAttendanceIsNull')
          }
          this.$notification.success({
            message: 'SUCCESS',
            description: 'Successfully upload file.',
          })
        }, 1000)
        this.fileAnswer = ''
        this.loadingAdd = false
      } catch (error) {
        await this.$store.dispatch('slot/FETCH_USER_ASSIGNMENT_BY_ID_SESSION', { idMurid, idSesi })
        this.$notification.error({
          message: 'FAILED',
          description: 'failed on uploading file.',
        })
        this.loadingAdd = false
        console.log(error)
      }
    },
    selectFile() {
      const file = this.$refs.file.$refs.input.files[0]
      // console.log(config.uploader.MAX_SIZE / 1000)
      const video = 'video/quicktime'
      const word = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      const xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const pdf = 'application/pdf'
      const jpg = 'image/jpeg'
      const png = 'image/png'
      const allowedTypes = [word, xlsx, pdf, jpg, png, video]
      const tooLarge = file.size > config.uploader.MAX_SIZE
      if (allowedTypes.includes(file.type) && !tooLarge) {
        // this.errorFile.status = false
        // this.errorFile.message = ''
        this.fileAnswer = file
      } else {
        this.fileAnswer = null
        this.$notification.error({
          message: 'Error',
          description: tooLarge ? `Your file too large. Max size is ${config.uploader.MAX_SIZE / 1000}Kb` : 'Only (DOCX, XLSX, PDF, JPG/JPEG, PNG) are allowed',
        })
      }
    },
  },
  computed: {
    currentSlot() {
      return this.$store.state.slot.currentSlot
    },
    user() {
      return this.$store.state.user.user
    },
    secondDueDateAndTime() {
      return moment(this.session.tugas_deadline2).locale('id').format('DD MMMM YYYY, HH:mm')
    },
    taskObj() {
      let type = null
      this.taskTypes.forEach(element => {
        // console.log(element)
        if (element.type === this.taskType) {
          type = element
        }
      })
      return type
    },
    editor() {
      return this.$store.state.ckEditor.editor
    },
    editorConfig() {
      return this.$store.state.ckEditor.editorConfig
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    institusi() {
      return this.$store.state.master.institusi
    },
  },
  created() {
    // console.log('ASSIGNMENT PREVIEW', this.session)
    if (this.user.role.includes('murid')) {
      const idSesi = this.$route.params.id
      this.$store.dispatch('slot/FETCH_USER_ASSIGNMENT_BY_ID_SESSION', {
        idMurid: this.user.id,
        idSesi,
      })
        .then((data) => {
          console.log(data, 'masukkk')
          if (data.data) {
            if (data.data?.essay_answer) {
              this.essayAnswer = data.data?.essay_answer || ''
            } else if (data.data?.tugas_filename) {
              this.statusFileDone = true
              this.fileAnswerDone.id = data.data?.id
              this.fileAnswerDone.tugas_filepath = data.data?.tugas_filepath
              this.fileAnswerDone.tugas_filename = data.data?.tugas_filename
            } else if (data.data?.jawaban_murid) {
              // console.log('JAWABAN PG MURID', data.data.jawaban_murid)
              this.multipleChoiceAnswer = JSON.parse(data.data.jawaban_murid)
            } else {
              for (let index = 0; index < this.session.pg_soals.length; index++) {
                this.multipleChoiceAnswer.push(0)
              }
            }
          } else {
            this.$emit('changeAttendanceIsNull')
          }
        })
    }
  },
  mounted() {
    let arrayOfRef = []
    const jawaban = this.$refs.jawaban
    const soal = this.$refs.soal
    const deskripsi = this.$refs.deskripsi
    if (jawaban || soal || deskripsi) {
      if (jawaban) {
        if (Array.isArray(jawaban)) arrayOfRef = arrayOfRef.concat(jawaban)
        else arrayOfRef.push(jawaban)
      }
      if (soal) {
        if (Array.isArray(soal)) arrayOfRef = arrayOfRef.concat(soal)
        else arrayOfRef.push(soal)
      }
      if (deskripsi) {
        if (Array.isArray(deskripsi)) arrayOfRef = arrayOfRef.concat(deskripsi)
        else arrayOfRef.push(deskripsi)
      }

      renderMathMl(arrayOfRef)
    }
  },
  components: {
    EmptyState,
  },
}
</script>
<style lang="scss">
.assignmentSchedule {
  .paddingLeft {
    padding-left: 5%;
  }

  .ckEditorContainer {
    .ck-editor__editable {
      min-height: 20rem;
    }
  }

  h1 {
    margin-top: 4% !important;
  }

  .textAssignment {
    font-family: "Mukta", "sans-serif";
    font-weight: 500;
    font-size: 16px;
    margin: auto;
    width: 65%;
    @media (max-width: 769px) {
      width: 100%;
    }
  }
}
</style>
