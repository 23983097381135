<template>
  <div :class="$style.liveClass">
    <a-modal
      title="Update Notes"
      :visible="modalVisible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      :maskStyle="{
        opacity: 0.5,
        backgroundColor: 'black',
      }"
      okText="Update"
      :okButtonProps="{
        props: {
          shape: 'round',
        },
      }"
      :cancelButtonProps="{
        props: {
          shape: 'round',
        },
      }"
    >
      <p>Are you sure to update your notes?</p>
    </a-modal>
    <a-row v-if="!isStudent && liveClass.zoom_setting && isNow && [null, 'hasancam'].includes(liveClass.video_conference)">
      <a-col :span="24">
        <div
          :style="{
            display: isDesktop || isTablet ? 'flex' : 'block',
            'justify-content': 'center',
          }"
          class="mb-4"
        >
          <template v-if="liveIsClicked">
            <div
              :style="{
                display: isMobile ? 'flex' : '',
                'justify-content': 'center',
              }"
            >
              <a-button
                :type="willDraw ? 'default' : 'primary'"
                :style="{
                  display: isMobile ? 'block' : '',
                  'margin-left': isMobile ? 'auto' : null,
                  'margin-right': isMobile ? 'auto' : null,
                }"
                @click.prevent="openDrawer"
                :class="[!isMobile ? 'mr-3' : 'my-2']"
                >{{ willDraw ? "Close" : "Open" }} Teacher Drawer</a-button
              >
            </div>
            <div
              :style="{
                display: isMobile ? 'flex' : '',
                'justify-content': 'center',
              }"
            >
              <a-button
                :class="[!isMobile ? '' : 'my-2']"
                :type="willCollaborateDraw ? 'default' : 'primary'"
                @click.prevent="openCollaborateDrawer"
                >{{ willCollaborateDraw ? "Close" : "Open" }} Collaborate
                Drawer</a-button
              >
            </div>
          </template>
        </div>
        <div
          v-if="session.slot"
          :class="$style['liveBox']"
          :style="{
            'background-color': liveIsClicked
              ? 'var(--kit-color-primary)'
              : '#ffffff',
            width: '100%',
          }"
        >
          <!-- <transition name="slide-right" mode="out-in"> -->
          <!-- <span>{{current.x}},{{current.y}}</span> -->
          <div
            v-show="willDraw"
            :class="[
              $style.drawer,
              willDraw
                ? $style['scale-up-hor-left']
                : $style['scale-down-hor-left'],
            ]"
          >
            <Whiteboard
              v-if="liveClass.zoom_setting && isNow"
              :config="configDrawer"
              :isCollaborate="false"
              :isStudent="isStudent"
            />
          </div>
          <div
            v-show="willCollaborateDraw"
            :class="[
              $style.drawer,
              willCollaborateDraw
                ? $style['scale-up-hor-left']
                : $style['scale-down-hor-left'],
            ]"
          >
            <Whiteboard
              v-if="liveClass.zoom_setting && isNow"
              :config="configDrawer"
              :isCollaborate="true"
              :isStudent="isStudent"
            />
          </div>
          <!-- </transition> -->
          <!-- <ZoomFrame /> -->
          <JitsiMeet
            v-if="liveIsClicked"
            ref="jitsiRef"
            :domain="jitsiDomain"
            :options="jitsiOptions"
          />
          <div v-else :class="$style['openCamBtn']">
            <label
              >Your class is live now. Click button below to enter class.</label
            >
            <a-button size="large" type="primary" @click.prevent="openCam"
              >Enter Class Now</a-button
            >
          </div>
        </div>
        <!-- </div> -->
      </a-col>
    </a-row>
    <template v-else-if="isStudent">
      <template v-if="isNow && liveClass.zoom_setting && [null, 'hasancam'].includes(liveClass.video_conference)">
        <a-drawer
          placement="right"
          :closable="false"
          width="540"
          :visible="visible"
          :after-visible-change="afterVisibleChange"
          @close="onClose"
        >
          <template slot="title">
            <a-row type="flex" align="bottom">
              <a-col :span="19">
                <h2>NOTES</h2>
              </a-col>
              <a-col :span="5">
                <a-button
                  shape="round"
                  icon="save"
                  style="
                    margin-bottom: 5px;
                    background-color: #41b883;
                    color: white;
                  "
                  @click.prevent="saveNotes"
                  >SAVE</a-button
                >
              </a-col>
            </a-row>
          </template>
          <div style="margin-top: 3%" :class="$style['ckEditorDrawer']">
            <!-- <wysiwyg style="height: 80vh" v-model="notes" /> -->
            <ckeditor
              :editor="editor"
              v-model="notes"
              :config="editorConfig"
            ></ckeditor>
            <!-- <a-textarea placeholder="Write your notes here and you can read again for later." :rows="28" /> -->
          </div>
        </a-drawer>
        <a-row>
          <a-col :span="24">
            <div
              :style="{
                display: isDesktop ? 'flex' : 'block',
                'justify-content': 'center',
              }"
              class="mb-4"
            >
              <div
                :style="{
                  display: isDesktop ? '' : 'flex',
                  'justify-content': 'center',
                }"
                :class="isDesktop ? '' : 'my-2'"
              >
                <a-button
                  :type="visible ? 'default' : 'primary'"
                  @click.prevent="showDrawer"
                  ><a-icon type="book" />
                  {{ visible ? "Close" : "Open" }} Notes</a-button
                >
              </div>
              <template v-if="liveIsClicked">
                <div
                  :style="{
                    display: isDesktop ? '' : 'flex',
                    'justify-content': 'center',
                  }"
                  :class="isDesktop ? '' : 'my-2'"
                >
                  <a-button
                    :type="willDraw ? 'default' : 'primary'"
                    @click.prevent="openDrawer"
                    class="mx-3"
                    >{{ willDraw ? "Close" : "Open" }} Teacher Drawer</a-button
                  >
                </div>
                <div
                  :style="{
                    display: isDesktop ? '' : 'flex',
                    'justify-content': 'center',
                  }"
                  :class="isDesktop ? '' : 'my-2'"
                >
                  <a-button
                    :type="willCollaborateDraw ? 'default' : 'primary'"
                    @click.prevent="openCollaborateDrawer"
                    >{{ willCollaborateDraw ? "Close" : "Open" }} Collaborate
                    Drawer</a-button
                  >
                </div>
              </template>
            </div>
            <div
              v-if="session.slot"
              :style="{
                'background-color': liveIsClicked
                  ? 'var(--kit-color-primary)'
                  : '#ffffff',
                width: '100%',
              }"
              :class="$style['liveBox']"
            >
              <div
                v-show="willDraw"
                :class="[
                  $style.drawer,
                  willDraw
                    ? $style['scale-up-hor-left']
                    : $style['scale-down-hor-left'],
                ]"
              >
                <Whiteboard
                  v-if="liveClass.zoom_setting && isNow"
                  :config="configDrawer"
                  :isCollaborate="false"
                  :onlyView="true"
                  :isStudent="isStudent"
                />
              </div>
              <div
                v-show="willCollaborateDraw"
                :class="[
                  $style.drawer,
                  willCollaborateDraw
                    ? $style['scale-up-hor-left']
                    : $style['scale-down-hor-left'],
                ]"
              >
                <Whiteboard
                  v-if="liveClass.zoom_setting && isNow"
                  :config="configDrawer"
                  :isCollaborate="true"
                  :isStudent="isStudent"
                />
              </div>
              <!-- </transition> -->
              <!-- <ZoomFrame /> -->
              <JitsiMeet
                v-if="liveIsClicked"
                ref="jitsiRef"
                :domain="jitsiDomain"
                :options="jitsiOptions"
              />
              <div v-else :class="$style['openCamBtn']">
                <p class="text-center">
                  Your class is live now. Click button below to enter class.
                </p>
                <a-button size="large" type="primary" @click.prevent="openCam"
                  >Enter Class Now</a-button
                >
              </div>
            </div>
          </a-col>
        </a-row>
      </template>
      <template v-else>
        <a-divider orientation="left">
          <h3>Notes</h3>
        </a-divider>
        <!-- <wysiwyg :class="$style.textMaterial" style="height: 80vh" v-model="notes" /> -->
        <div :class="$style.textMaterial">
          <ckeditor
            @focus="ckEditorFocus"
            :editor="editor"
            v-model="notes"
            :config="editorConfig"
          ></ckeditor>
          <!-- {{notes}} -->
        </div>
        <div :class="$style.textMaterial">
          <a-button
            shape="round"
            icon="save"
            size="large"
            style="margin-top: 20px;
            margin-bottom: 20px;
            margin-left: auto;
            background-color: #41B883;
            color: white;
            display: block;
            padding: 0px 40px
            font-size: 1em;"
            @click.prevent="saveNotes"
            >SAVE</a-button
          >
        </div>
      </template>
    </template>
    <div v-if="!isStudent && attendanceAuthorized">
      <a-row style="margin-bottom: 2%">
        <a-col :span="attendances.length && !isMobile ? 19 : 24">
          <h2 style="color: #041930">ATTENDANCE LIST</h2>
        </a-col>
        <a-col
          v-if="attendances.length"
          :span="5"
          style="text-align: right; margin-top: 20px"
        >
          <a-button
            shape="round"
            style="background-color: #41b883; color: white"
            icon="check-circle"
            @click.prevent="allAttend"
            >ALL ATTEND</a-button
          >
        </a-col>
      </a-row>
      <div :class="$style.statusNotes">
        <span class="mr-2">
          <span
            class="kit__utils__donut kit__utils__donut--not-absent-yet"
          ></span>
          Not Absent Yet
        </span>
        <span class="mr-4">
          <span class="kit__utils__donut kit__utils__donut--present"></span>
          Present
        </span>
        <span class="mr-4">
          <span class="kit__utils__donut kit__utils__donut--absent"></span>
          Absent
        </span>
        <span class="mr-4">
          <span class="kit__utils__donut kit__utils__donut--sick"></span>
          Sick Leave
        </span>
        <span class="mr-4">
          <span class="kit__utils__donut kit__utils__donut--leave"></span>
          Leave
        </span>
      </div>
      <a-row :gutter="[16, 16]">
        <template v-if="attendances.length">
          <template v-for="student in attendances">
            <a-col :sm="24" :md="12" :lg="6" :xxl="4" :key="student.id">
              <CardAttendance
                :student="student"
                :attendancePercentage="
                  student.percentageKehadiran &&
                  !isNaN(student.percentageKehadiran)
                    ? parseFloat(student.percentageKehadiran.toFixed(2))
                    : 0
                "
                :session="session"
              />
            </a-col>
          </template>
        </template>
        <EmptyState
          v-else
          heading="No Data"
          description="You Have No Attendance Datas"
        />
      </a-row>
    </div>
    <div>
      <div v-if="isNow && liveClass.zoom_setting">
        <h2 style="color: #041930; margin-top: 50px">SESSION INFO</h2>
        <br />
      </div>
      <a-divider v-else orientation="left">
        <h3>Session Info</h3>
      </a-divider>
      <a-row>
        <a-col :class="[!isDesktop ? 'mb-3' : '']" :span="isDesktop ? 6 : 12">
          <div style="margin-bottom: 2% !important">
            <b>Week No.</b>
          </div>
          <p>
            <span style="color: #1b55e3">
              <b>{{ week }}</b>
            </span>
            /24
          </p>
        </a-col>
        <a-col :class="[!isDesktop ? 'mb-3' : '']" :span="isDesktop ? 6 : 12">
          <div style="margin-bottom: 2% !important">
            <b>Duration</b>
          </div>
          <p>
            {{durasi}}
          </p>
        </a-col>
        <a-col :class="[!isDesktop ? 'mb-3' : '']" :span="isDesktop ? 6 : 12">
          <div style="margin-bottom: 2% !important">
            <b>Live</b>
          </div>

          <p v-if="session.zoom_setting" style="color: #52c41a">
            <a-icon
              type="video-camera"
              style="font-size: 20px"
              theme="twoTone"
              two-tone-color="#52c41a"
            />&nbsp;Live
          </p>
          <p v-if="!session.zoom_setting">Non-Live Class</p>
        </a-col>
        <a-col :class="[!isDesktop ? 'mb-3' : '']" :span="isDesktop ? 6 : 12">
          <div style="margin-bottom: 2% !important">
            <b>Updated</b>
          </div>
          <p>{{ updatedAt }}</p>
        </a-col>
        <!-- <a-col :span="12">
          <a-row>
            <a-col :span="12">
              <div style="margin-bottom: 2%!important;">
                <b>Live</b>
              </div>

              <p v-if="session.zoom_setting" style="color: #52c41a">
                <a-icon
                  type="video-camera"
                  style="font-size:20px;"
                  theme="twoTone"
                  two-tone-color="#52c41a"
                />&nbsp;Live
              </p>
              <p v-if="!session.zoom_setting">Non-Live Class</p>
            </a-col>
            <a-col :span="12">
              <div style="margin-bottom: 2%!important;">
                <b>Updated</b>
              </div>
              <p>{{updatedAt}}</p>
            </a-col>
          </a-row>
        </a-col> -->
      </a-row>
    </div>
  </div>
</template>
<script>
import CardAttendance from '@/components/app/DetailSchedule/CardAttendance'
// import ZoomFrame from '@/components/app/ZoomFrame'
import moment from 'moment'
import EmptyState from '@/components/app/EmptyState'
import $ from 'jquery'
import { jitsiToken } from '@/helpers/token.js'
// import { ZoomMtg } from '@zoomus/websdk'
import JitsiMeet from '@/components/app/JitsiMeet'
// import Whiteboard from '@/components/app/Whiteboard'
// import socket from '@/socket.js'
// import Konva from 'konva'

export default {
  mounted() {
    window.addEventListener('keyup', (event) => {
      if (event.keyCode === 37) {
        this.visible = true
      } else if (event.keyCode === 39) {
        this.visible = false
      }
      $('#app').append($('div.ck-body-wrapper'))
    })
    // const idSession = this.$route.params.id
    // this.$store.dispatch('slot/FETCH_CURRENT_SLOT', { idSession })
  },
  created() {
    // ZoomMtg.setZoomJSLib('https://source.zoom.us/1.7.8/lib', '/av')
    // ZoomMtg.setZoomJSLib('http://demo.hasanteknologi.id:8080/node_modules/@zoomus/websdk/dist/lib', '/av')
    // ZoomMtg.preLoadWasm()
    // ZoomMtg.prepareJssdk()
  },
  data() {
    const idSesi = this.$route.params.id
    return {
      visible: false,
      notes: this.assignmentStudent && this.assignmentStudent.notes ? this.assignmentStudent.notes : '',
      moment,
      modalVisible: false,
      confirmLoading: false,
      isDrawing: false,
      current: {
        shapeX0: 0,
        shapeY0: 0,
        x: 0,
        y: 0,
        color: '#000000',
        strokeWidth: 2,
      },
      willDraw: false,
      willCollaborateDraw: false,
      configDrawer: {},
      drawerRefs: {},
      toolDrawer: 'pencil',
      dataHistory: [],
      dataHistoryCol: [],
      drawObj: {
        lines: [],
        rects: [],
      },
      rects: [],
      isDrawingShape: false,
      liveIsClicked: false,
      idSesi,
    }
  },
  components: {
    CardAttendance,
    // ZoomFrame,
    EmptyState,
    JitsiMeet,
    Whiteboard: () => import('@/components/app/Whiteboard'),
  },
  props: ['isStudent', 'liveClass', 'isNow', 'isDone', 'assignmentStudent', 'attendances', 'model', 'session', 'attendanceAuthorized'],
  watch: {
    'assignmentStudent.notes'(newVal, oldVal) {
      if (newVal) {
        this.notes = newVal
      }
    },
    notes(newVal, oldVal) {
      // const regexImages = /(?<=images\/).+?(?=")/gm
      const regexImages = /(images\/).+?(?=")/gm
      // let image
      if (newVal) {
        const arrImage = newVal.match(regexImages)
        // console.log(arrImage, newVal)
        if (arrImage) {
          this.$emit('addUploadedImages', arrImage[0].split('images/')[1])
        }
      }
    },
  },
  methods: {
    openCam() {
      this.$store.commit('live/SET_STATE', {
        isLive: true,
      })
      this.liveIsClicked = true
    },
    openDrawer() {
      this.willDraw = !this.willDraw
      if (this.willCollaborateDraw) {
        this.willCollaborateDraw = false
      }
      // const w = $('#containerCanvas').parent().width()
      // const h = $('div.drawer').height()
      // console.log('ada lo', w, h, $('.konvajs-content').parent().width())
    },
    openCollaborateDrawer() {
      this.willCollaborateDraw = !this.willCollaborateDraw
      if (this.willDraw) {
        this.willDraw = false
      }
      // const w = $('#containerCanvas').parent().width()
      // const h = $('div.drawer').height()
      // console.log('ada lo', w, h, $('.konvajs-content').parent().width())
    },
    ckEditorFocus(event) {
      // console.log(event)
    },
    saveNotes() {
      this.modalVisible = true
    },
    handleOk(e) {
      // e.preventDefault()
      this.confirmLoading = true
      this.visible = false
      this.$store.dispatch('attendance/SAVE_NOTES_BY_ID_ATTENDANCE', {
        idMurid: this.user.id,
        idSesi: this.idSesi,
        notes: this.notes,
      })
        .then(isSuccess => {
          setTimeout(() => {
            this.modalVisible = false
            this.confirmLoading = false
            if (isSuccess) {
              this.$notification.success({
                message: 'Successfully update notes',
              })
            } else {
              this.$notification.error({
                message: 'Sorry.',
                description: 'Notes can\'t be updated right now. Try again later.',
              })
            }
          }, 2000)
        })
    },
    handleCancel(e) {
      // e.preventDefault()
      this.modalVisible = false
    },
    allAttend() {
      this.$store.commit('attendance/SET_STATE', {
        allIsAttend: true,
      })
      this.$store.dispatch('attendance/UPDATE_ALL_ABSENSI_BY_SESSION', {
        idKelas: this.session.slot.id_kelas,
        tanggalSesi: this.session.tanggal_sesi,
        idSesi: this.session.id,
      })
        .then(data => {
          if (data) {
            // console.log('oke')
          }
        })
    },
    afterVisibleChange(val) {
      // console.log('visible', val)
    },
    showDrawer() {
      // console.log('ooke')
      this.visible = true
    },
    onClose() {
      this.visible = false
    },
  },
  computed: {
    listHasanCam() {
      return ['hasancam.hasanteknologi.id', 'hasancam2.hasanteknologi.id', 'hasancam3.hasanteknologi.id']
    },
    jitsiDomain () {
      const { urutan } = this.session.slot?.kela?.level
      if (!urutan || !this.listHasanCam[urutan]) return this.listHasanCam[0]
      return this.listHasanCam[urutan]
      // return 'hasancam.hasanteknologi.id'
    },
    jitsiOptions () {
      return {
        roomName: `Kelas${this.session.slot.kela.tingkat}${this.session.slot.kela.simbol}`,
        noSSL: false,
        userInfo: this.isStudent ? {
          email: 'user@email.com',
          // displayName: 'admin sibayan',
          displayName: `${this.session.slot.kela.tingkat}-${this.session.slot.kela.simbol} / ${this.user.nama}`,
        } : '',
        configOverwrite: {
          // enableNoisyMicDetection: true,
        },
        interfaceConfigOverwrite: {
          SHOW_JITSI_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
          SHOW_POWERED_BY: false,
          SHOW_PROMOTIONAL_CLOSE_PAGE: false,
          TOOLBAR_BUTTONS: this.toolbarButtons,
          // maxFullResolutionParticipants: 0,
          // JITSI_WATERMARK_LINK: 'http://localhost:8080',
          // DEFAULT_LOGO_URL: 'images/Logo-Hasan.png',
          // JICOFO_SECRET: ''
          // JICOFO_AUTH_USER: 'moderator',
          // JICOFO_AUTH_PASSWORD: 'moderator',
        },
        onload: this.onIFrameLoad,
        jwt: !this.isStudent ? this.token : '',
      }
    },
    editor() { return this.$store.state.ckEditor.editor },
    editorConfig() {
      // console.log(this.$store.state.ckEditor.editorConfig)
      return this.$store.state.ckEditor.editorConfig
    },
    allIsAttend() { return this.$store.state.attendance.allIsAttend },
    isMobile() { return this.$store.getters.isMobile },
    isTablet() { return this.$store.getters.isTablet },
    isDesktop() { return !this.isMobile && !this.isTablet },
    user() { return this.$store.state.user.user },
    updatedAt() { return moment(this.session.updatedAt).format('DD MMMM YYYY, HH:mm') },
    toolbarButtons() {
      const toolbar = [
        'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
        'fodeviceselection', 'profile', 'chat', 'recording',
        'livestreaming', 'sharedvideo', 'settings', 'raisehand',
        'videoquality', 'filmstrip', 'feedback', 'stats', 'hangup', 'shortcuts',
        'tileview', 'download', 'help', 'mute-everyone', 'security',
      ]
      // if (!this.isStudent) {
      //   toolbar.push('hangup')
      // }
      return toolbar
    },
    token() {
      return jitsiToken({
        context: {
          user: {
            name: this.isStudent ? `${this.session.slot.kela.tingkat}-${this.session.slot.kela.simbol} / ${this.user.nama}` : this.user.nama,
            email: 'user@mail.com',
          },
        },
        aud: 'hasandigitalinovasi',
        iss: 'hasandigitalinovasi',
        sub: 'hasancam.hasanteknologi.id',
        room: '*',
      })
    },
    durasi() {
      if (this.session.durasi !== null && this.session.durasi !== undefined) {
        const hours = Math.floor(this.session.durasi / 60)
        const minutes = Math.floor(this.session.durasi % 60)
        return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
      }
      return '00:00'
    },
    week() { return this.session.week?.week || '-' },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
